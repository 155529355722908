import {Component,Input, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../../shared/components/base.component";
import {NaturalTextActionsService} from "../../services/natural-text-actions.service";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Page} from "../../shared/models/page";
import {NaturalTextActions} from "../../models/natural-text-actions.model";
import {WorkspaceVersionService} from "../../shared/services/workspace-version.service";
import {WorkspaceVersion} from "../../models/workspace-version.model";
import {expand, fade} from "../../shared/animations/animations";
import {CdkConnectedOverlay} from '@angular/cdk/overlay';
import {NaturaltextActionExample} from "../../models/natural-text-action-example.model";
import {TestCase} from "../../models/test-case.model";
import {TestStepType} from "../../enums/test-step-type.enum";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import mixpanel from 'mixpanel-browser';

@Component({
    selector: 'app-help-text',
    templateUrl: './list.component.html',
    host: {'class': 'page-content-container'},
    animations: [fade, expand],
  })
  
export class ListComponent extends BaseComponent implements OnInit {
    @Input('testcase') testcase: TestCase;
    @Input('version') version: WorkspaceVersion;
    @Input('stepType') stepType: string;
    public videoUrlString: SafeUrl;
    public showVideo: Boolean = false;


    ngOnInit(): void {
      // mixpanel.identify(window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail'));
      mixpanel.people.set({ "Plan": "Premium" ,"origin":window.location.hostname.split('.')[0], "email": localStorage.getItem('useremail')});
      mixpanel.track('Help Template');
    }
    get isRest() {
        return this.stepType == TestStepType.REST_STEP;
    }
    

    videoUrl(): SafeUrl {
        let url = "";
        if (this.testcase) {
          url = this.version?.workspace?.isWeb && !this.isRest ? 'https://s3.amazonaws.com/assets.contextqa.com/videos/test-cases/web/create-steps.mp4' :
            !this.version?.workspace?.isMobileNative ? 'https://s3.amazonaws.com/assets.contextqa.com/videos/test-cases/mobile-web/create-steps.mp4' :
              this.version?.workspace?.isAndroidNative ? 'https://s3.amazonaws.com/assets.contextqa.com/videos/test-cases/android/create-steps.mp4' :
                this.version?.workspace?.isIosNative ? 'https://s3.amazonaws.com/assets.contextqa.com/videos/test-cases/ios/create-steps.mp4' :
                  this.isRest ? 'https://s3.amazonaws.com/assets.contextqa.com/videos/test-cases/rest-api/create-steps.mp4' :
                    'https://s3.amazonaws.com/assets.contextqa.com/videos/test-cases/rest-api/create-steps.mp4';
        }
        // this.videoUrlString = this.sanitizer?.bypassSecurityTrustResourceUrl(url);
        return this.videoUrlString;
      }
  
}