import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NaturalTextActions } from "../../models/natural-text-actions.model";
import { Page } from "../../shared/models/page";
import { TestCase } from "../../models/test-case.model";
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { TestStepType } from "../../enums/test-step-type.enum";
import { NaturalTextActionsService } from "../../services/natural-text-actions.service";
import Prism from "prismjs";
import mixpanel from "mixpanel-browser";
import { TestCaseService } from "app/services/test-case.service";
import { ActivatedRoute } from "@angular/router";
import { TestCaseStatus } from "app/enums/test-case-status.enum";
import { TestStep } from "app/models/test-step.model";
import { TestSuiteService } from "app/services/test-suite.service";
import { TestPlanService } from "app/services/test-plan.service";
import { TestStepService } from "app/services/test-step.service";
import { Socket } from "ngx-socket-io";
import { BaseComponent } from "app/shared/components/base.component";
import { AuthenticationGuard } from "app/shared/guards/authentication.guard";
import { NotificationsService, NotificationType } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-test-step-help",
  templateUrl: "./test-step-help.component.html",
  styles: [
    `
      .log-item {
        border: 5px solid rgb(210, 210, 210);
        padding: 10px;
        margin-bottom: 10px;
        word-wrap: break-word;
      }
      .ai-response-container {
        border: 2px solid #ccccff;
        padding: 10px;
        border-radius: 8px;
        background-color: #f5f5ff;
        font-family: Arial, sans-serif;
      }

      .ai-response-header {
        background-color: #5f52d1;
        color: white;
        padding: 10px;
        border-radius: 8px 8px 0 0;
        text-align: center;
      }

      .steps-container {
        padding: 10px;
      }

      .step {
        background-color: #705fe6;
        color: white;
        padding: 10px;
        margin: 5px 0;
        border-radius: 6px;
      }

      .create-btn-container {
        text-align: center;
        margin-top: 10px;
      }

      .create-btn {
        background-color: #28a745;
        color: white;
        padding: 8px 16px;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-size: 16px;
      }

      .create-btn:hover {
        background-color: #218838;
      }

      .err {
        border-color: #f4c4c4;
      }
      .success {
        border-color: green;
      }
      .warning {
        border-color: yellow;
      }
      .default {
        border-color: grey;
      }
      @keyframes spinner {
        to {
          transform: rotate(360deg);
        }
      }

      .mat-flat-button.mat-primary,
      .mat-raised-button.mat-primary,
      .mat-fab.mat-primary,
      .mat-mini-fab.mat-primary {
        background-color: blue !important;
      }

      .spinner:before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid #ffffff;
        border-top-color: #000000;
        animation: spinner 0.8s linear infinite;
      }

      h2 {
        color: #333;
        margin-bottom: 16px;
        display: flex;
        gap: 10px;
      }

      .instructions {
        margin-bottom: 20px;
        line-height: 1.5;
        color: #555;
      }

      .suggest-button {
        position: relative;
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        overflow: hidden;
        background: #d9dafe;
      }

      .suggest-button mat-icon {
        margin-right: 8px;
      }

      .loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.1);
      }

      .loader::after {
        content: "";
        width: 30px;
        height: 30px;
        border: 3px solid #ffffff;
        border-top: 3px solid transparent;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `,
  ],
})
export class TestStepHelpComponent extends BaseComponent implements OnInit {
  @Input("templates") templates: Page<NaturalTextActions>;
  @Input("testcase") testcase: TestCase;
  @Input("version") version: WorkspaceVersion;
  @Input("stepType") stepType: string;
  @Input("clickFromHelp") clickFromHelp: boolean;
  @Output("onClose") onClose = new EventEmitter<void>();
  @Output("reloadStep") reloadStep = new EventEmitter<void>();
  @Output("onSelectTemplate") onSelectTemplate =
    new EventEmitter<NaturalTextActions>();
  public testSteps: any[];
  public activeTab: string;
  javaCode: any = "";
  public IsLoading: boolean = false;
  cqaOrigin: any;
  public testcaseDetails: any;
  isLoading = false;
  isLoadingSuggestedTestCases = false;

  constructor(
    private naturalTextActionsService: NaturalTextActionsService,
    public route: ActivatedRoute,
    public testSuiteService: TestSuiteService,
    public teststepService: TestStepService,
    public testPlanService: TestPlanService,
    private testCaseService: TestCaseService,
    private socket: Socket,
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
  ) {
    super(authGuard, notificationsService, translate, toastrService);
  }

  ngOnInit(): void {
    let query = "testCaseId:" + this.testcase.id;
    this.teststepService.findAll(query).subscribe((result) => {
      this.testSteps = result.content;
      console.log(result);
    });

    this.cqaOrigin = window.location.hostname.split(".")[0];
  }

  ngOnChanges() {
    this.activeTab = !this.isActionText ? "help" : "run_results";
  }

  get isActionText() {
    return this.stepType == TestStepType.ACTION_TEXT;
  }

  helpClose() {
    this.onClose.emit();
  }

  generateAICode() {
    try {
      this.IsLoading = true;
      // mixpanel.identify(
      //   window.location.hostname.split(".")[0] +
      //     "-" +
      //     localStorage.getItem("useremail")
      // );
      mixpanel.people.set({
        Plan: "Premium",
        origin: window.location.hostname.split(".")[0],
        email: localStorage.getItem("useremail"),
      });
      mixpanel.track("Generate AI Code");
      this.naturalTextActionsService
        .generateAI(this.testcase.id)
        .subscribe((res) => {
          this.IsLoading = false;
          const grammar = Prism.languages["java"];
          this.javaCode = Prism.highlight(
            res.code,
            Prism.languages.javascript,
            "javascript"
          );
        });
    } catch (e) {
      this.IsLoading = false;
    }
  }

  SelectTemplate(template: NaturalTextActions) {
    this.onSelectTemplate.emit(template);
  }
  fixedData(): void {
    this.reloadStep.emit();
  }
  handleNullResult(): void {
    if (!this.clickFromHelp) {
      this.onClose.emit();
    }
  }

  suggestTestCase() {
    this.testSteps = this.testSteps.sort((a, b) => a.position - b.position);
    this.isLoading = true;
    let testSuggestion = this.testSteps.map((item) => {
      let action = item.action ? item.action : "";
      let event = item.event?.customEvent
        ? item.event.customEvent.toLowerCase()
        : "";
      let metadata = item.metadata ? item.metadata : null;
      let image = item.goldenScreenshot ? item.goldenScreenshot : null;
      // Define mappings for each custom event type
      if (event === "navigateto") {
        event = "navigateTo";
        metadata = item.testDataVal
          ? `${metadata} ${item.testDataVal}`
          : metadata;
      } else if (event === "ai_text_actions") {
        event = "ai_text_verification";
      }

      return {
        action,
        event,
        image,
        metadata,
        label: null,
      };
    });
    this.testCaseService.suggestTestcase({ steps: testSuggestion }).subscribe(
      (result: any) => {
        this.testcaseDetails = result.data;
        this.isLoading = false;
      },
      (error) => {
        console.error("Error suggesting test case:", error);
        this.isLoading = false;
      }
    );
  }

  async createTestcase(data) {
    let testCase = await this.createTestCaseCreateData(data);
    this.testCaseService.autocreate(testCase).subscribe((testcase) => {
      if (testcase.id) {
        window.open(`${origin}/td/cases/${testcase.id}/steps`, "_blank");
      }
      console.log(testcase);
    });
  }

  async createTestplan() {
    let testCaseIds = [];
    for (let testCases of this.testcaseDetails.testCases) {
      let testCase = await this.createTestCaseCreateData(testCases);
      let testcaseResp = await this.testCaseService
        .autocreate(testCase)
        .toPromise();
      if (testcaseResp && testcaseResp.id) {
        testCaseIds.push(testcaseResp.id);
      }
    }
    let testSuitData = {
      name: this.testcaseDetails.name,
      description: null,
      workspaceVersionId: "1",
      testCaseIds: testCaseIds,
      tags: [],
    };
    let testSuitDetails = await this.testSuiteService
      .autocreate(testSuitData)
      .toPromise();
    let testPlandata = await this.createTestPlanData(
      [testSuitDetails?.id],
      this.testcaseDetails.name
    );
    this.testPlanService.autocreate(testPlandata).subscribe((testplan: any) => {
      if (testplan && testplan.id) {
        window.open(`${origin}/td/plans/${testplan.id}/details`, "_blank");
      }
    });
  }

  async createTestCaseCreateData(data) {
    let testCaseSteps = [];
    await Promise.all(
      data.steps
        .filter((data) => data.action != "STEP_GROUP")
        .map(async (data, index) => {
          // Find the corresponding naturalTextDetail

          data.event = { customEvent: data.action };
          if (
            data.action == "navigateTo" ||
            data.action == "navigatetourl" ||
            data.action == "navigateToUrl"
          ) {
            data.actionName = "navigateToUrl";
            data.event = { customEvent: data.actionName };
            data.event.href = data.data[0];
            data.action = `Navigate to <span data-key=\"test-data\" data-event-key=\"href\" class=\"test_data action-flex-auto\" data-test-data-type=\"undefined\">${data.data[0]}</span>`;
            data.testData = data.data[0];
          } else if (data.action === "enter") {
            data.event.value = data.data[0];
            data.event.label = data.label;
          } else {
            data.event.label = data.label;
          }
          data.event.parsedSelector = data.locators?.parsedSelector;
          data.event.pwLocator = [data.locators?.selector || data.label];
          data.event.selector = data.locators?.selector || data.label;

          // Find natural te
          data.actionName = data.event.customEvent;
          data.type = "ACTION_TEXT";
          data.testDataType = "raw";
          data.metadata = data.description;
          data.waitTime = 30;
          data.priority = "MAJOR";
          data.isRunTime = false;
          data.ignoreStepResult = false;
          data.disabled = false;
          data.position = index;

          delete data.id;
          delete data.data;
        })
    );
    const Ai_task = await data.steps
      .filter(
        (data) =>
          data.actionName !== "navigateToUrl" &&
          data.actionName !== "STEP_GROUP"
      ) // Only include objects where shouldjoin is true
      .map((data) => data.name) // Extract the name property
      .join(" ");

    const navigateStep = await data.steps.find(
      (data) => data.actionName == "navigateToUrl"
    );
    if (navigateStep) {
      testCaseSteps.push({ ...navigateStep, naturalTextActionId: 425 });
    }
    if (Ai_task) {
      let Ai_agent_template = {
        action: `AI Agent <span data-key=\"element\" data-event-key=\"ai_task\" class=\"element action-flex-auto\">${Ai_task}</span>`,
        actionName: "ai_text_actions",
        event: {
          customEvent: "ai_text_actions",
          ai_task: Ai_task,
          description: Ai_task,
        },
        naturalTextActionId: 568,
        type: "ACTION_TEXT",
        disabled: false,
        ignoreStepResult: false,
        visualEnabled: false,
        position: 1,
      };
      testCaseSteps.push(Ai_agent_template);
    }
    return {
      isExtensionUsed: false,
      name: data.name,
      description: null,
      status: TestCaseStatus.READY,
      sendMailNotification: false,
      isStepGroup: false,
      priorityId: 1,
      type: 1,
      preRequisite: null,
      isDataDriven: false,
      workspaceVersionId: this.version.workspaceId,
      deleted: false,
      testDataStartIndex: 0,
      tags: [],
      steps: testCaseSteps,
    };
  }

  createTestPlanData(suiteIds, name) {
    return {
      workspaceVersionId: 1,
      name: name,
      description: "",
      parallelNode: 1,
      elementTimeOut: 30,
      pageTimeOut: 30,
      screenshot: "ALL_TYPES",
      recoveryAction: "Run_Next_Testcase",
      onAbortedAction: "Reuse_Session",
      onSuitePreRequisiteFail: "Abort",
      onTestCasePreRequisiteFail: "Abort",
      onStepPreRequisiteFail: "Run_Next_Testcase",
      reRunType: "NONE",
      testPlanLabType: "ContextQALab",
      testPlanType: "DISTRIBUTED",
      hideInDashboard: false,
      retrySessionCreation: null,
      retrySessionCreationTimeout: null,
      testDevices: [
        {
          id: null,
          browser: "chromium",
          platform: "Browser",
          browserVersion: null,
          platformOsVersionId: null,
          platformBrowserVersionId: null,
          platformScreenResolutionId: null,
          platformDeviceId: null,
          capabilities: "[]",
          deviceId: null,
          title: name,
          matchBrowserVersion: false,
          createSessionAtCaseLevel: false,
          suiteIds: suiteIds,
          prerequisiteTestDevicesId: null,
          prerequisiteTestDevicesIdIndex: null,
          testPlanLabType: "ContextQALab",
          workspaceVersionId: 1,
        },
      ],
      matchBrowserVersion: false,
      tags: [],
    };
  }

  suggestedTestCases() {
    this.isLoadingSuggestedTestCases = true;
    this.teststepService
      .suggestionTestCase(this.testcase.id)
      .subscribe((res: any) => {
        if (res) {
          this.socket.on(res.topic, (data: any) => {
            this.isLoadingSuggestedTestCases = false;
            this.testcaseDetails = data.data.result;
            if(data.data.log) {
              this.showNotification(NotificationType.Error, data.data.log);
            }
            this.socket.removeAllListeners(res.topic);
          });
        }
      });
  }
}
