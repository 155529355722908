import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TestCaseStatus } from 'app/enums/test-case-status.enum';
import { TestSuiteService } from 'app/services/test-suite.service';
import { TestStepService } from 'app/services/test-step.service';
import { TestPlanService } from 'app/services/test-plan.service';
import { TestCaseService } from 'app/services/test-case.service';
import mixpanel from 'mixpanel-browser';

@Component({
  selector: 'app-ai-test-case-display',
  template: `
    <div class="ai-test-case-container" *ngIf="testcaseDetails">
      <div class="ai-header">
        <mat-icon class="ai-icon">psychology</mat-icon>
        <h2>{{ testcaseDetails.name }}</h2>
      </div>
      <mat-card class="ai-card" [@cardAnimation]>
        <mat-card-content>
          <mat-accordion>
            <mat-expansion-panel *ngFor="let testSteps of testcaseDetails.testCases; let i = index" 
                                 [expanded]="i === expandedIndex"
                                 (opened)="expandedIndex = i">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="test-icon">description</mat-icon>
                  {{ testSteps.name }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              
              <div class="steps-container">
                <div class="step" *ngFor="let data of testSteps.steps; let j = index" [@stepAnimation]>
                  <span class="step-number">{{ j + 1 }}</span>
                      <span *ngIf="!data.isEditing" class="step-name">{{ data.name }}</span>
                       <input *ngIf="data.isEditing" [(ngModel)]="data.name" class="editable-step-name" />
                  <a class="mat-tooltip-trigger action-icon py-10 fa-pencil-on-paper  " (click)="data.isEditing = !data.isEditing"></a>
                </div>
              </div>
              
              <mat-action-row>
                <button mat-raised-button color="primary" (click)="createTestcase(testSteps)" [disabled]="isLoadingCreateTestCase">
                  <mat-icon>add</mat-icon> Create Test Case
                  <div *ngIf="isLoadingCreateTestCase" class="loader"></div>
                </button>
              </mat-action-row>
            </mat-expansion-panel>
          </mat-accordion>
          
          <div class="create-plan-container" *ngIf="isTestPlanButton">
            <button mat-raised-button color="accent" (click)="createTestplan()" [disabled]="isLoadingCreateTestPlan">
              <mat-icon>playlist_add</mat-icon> Create Test Plan
              <div *ngIf="isLoadingCreateTestPlan" class="loader"></div>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  `,
  styles: [`
    .ai-test-case-container {
      max-width: 800px;
      margin: 20px auto;
      font-family: 'Roboto', sans-serif;
    }

    .ai-header {
      display: flex;
      align-items: center;
      background: linear-gradient(135deg, #3f51b5, #2196f3);
      color: white;
      padding: 12px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      z-index: 1;
      position: relative;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
      .editable-step-name {
     border: none; 
     background: none; 
     outline: none; 
     width: -webkit-fill-available;}

    .ai-header .ai-icon {
      margin-right: 12px;
      font-size: 32px;
      height: 32px;
      width: 32px;
    }

    .ai-header h2 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }

    .ai-card {
      background: #ffffff;
      border-radius: 15px;
      box-shadow: 0 8px 32px rgba(31, 38, 135, 0.1);
      padding-top: 22px;
      overflow: hidden;
    }

    mat-accordion {
      margin-top: 16px;
    }

    mat-expansion-panel {
      margin-bottom: 12px;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }

    mat-expansion-panel-header {
      height: 64px;
    }

    .test-icon {
      margin-right: 8px;
      color: #3f51b5;
    }

    .steps-container {
      margin-top: 16px;
      padding: 0 16px;
    }

    .step {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      padding: 12px;
      background-color: #f5f5f5;
      border-radius: 8px;
      transition: all 0.3s ease;
    }
.step .action-icon {
  display: none; 
}

.step:hover .action-icon {
  display: inline-block; 
}
    .step:hover {
      background-color: #e0e0e0;
      transform: translateY(-1px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .step-number {
      min-width: 28px;
      height: 28px;
      background-color: #3f51b5;
      color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      font-weight: bold;
      font-size: 14px;
    }

    .step-name {
      flex-grow: 1;
      font-size: 16px;
    }

    .create-plan-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      padding: 0 16px;
      margin-bottom:100px;
    }

    button {
      transition: all 0.3s ease;
      font-weight: 500;
      letter-spacing: 0.5px;
    }

    button:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    button mat-icon {
      margin-right: 8px;
    }

    .loader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .loader::after {
      content: '';
      width: 30px;
      height: 30px;
      border: 3px solid #ffffff;
      border-top: 3px solid transparent;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `],
  animations: [
    trigger('cardAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ]),
    trigger('stepAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-20px)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateX(0)' }))
      ])
    ])
  ],
})
export class AiTestCaseDisplayComponent {
  @Input() testcaseDetails: any;
  @Input() version: any;
  @Input() isTestPlanButton: boolean = false;
  expandedIndex: number = 0;
  isLoadingCreateTestCase: boolean = false;
  isLoadingCreateTestPlan: boolean = false;

  constructor(
    public testSuiteService: TestSuiteService,
    public teststepService: TestStepService,
    public testPlanService: TestPlanService,
    private testCaseService: TestCaseService) {
  }

  async createTestcase(data) {
    this.isLoadingCreateTestCase = true;
    let testCase = await this.createTestCaseCreateData(data)
    this.testCaseService.autocreate(testCase).subscribe((testcase) => {
          // mixpanel.identify(window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail'));
          mixpanel.people.set({ "Plan": "Premium" ,"origin": window.location.hostname.split('.')[0]});
          mixpanel.track('Test Case Created', {
            "id": testcase.id,
            "title": testCase.name,
            "status": testCase.status,
            "method": "AI-chat"
          });
      if (testcase.id) {
        window.open(`${origin}/td/cases/${testcase.id}/steps`, '_blank');
      } console.log(testcase);
      this.isLoadingCreateTestCase = false;
    });
  }

  async createTestplan() {
    this.isLoadingCreateTestPlan = true;
    let testCaseIds = []
    for (let testCases of this.testcaseDetails.testCases) {
      let testCase = await this.createTestCaseCreateData(testCases);;
      let testcaseResp = await this.testCaseService.autocreate(testCase).toPromise();
      if (testcaseResp && testcaseResp.id) {
        testCaseIds.push(testcaseResp.id);
      }
    }
    let testSuitData = {
      "name": this.testcaseDetails.name,
      "description": null,
      "workspaceVersionId": "1",
      "testCaseIds": testCaseIds,
      "tags": []
    }
    let testSuitDetails = await this.testSuiteService.autocreate(testSuitData).toPromise();
    let testPlandata = await this.createTestPlanData([testSuitDetails?.id], this.testcaseDetails.name);
    this.testPlanService.autocreate(testPlandata).subscribe((testplan: any) => {
      if (testplan && testplan.id) {
        window.open(`${origin}/td/plans/${testplan.id}/details`, '_blank');
        this.isLoadingCreateTestPlan = false;
      }
    });
  }

  async createTestCaseCreateData(data) {
    let testCaseSteps = [];
    await Promise.all(data.steps.filter((data) => data.action != "STEP_GROUP").map(async (data, index) => {
      // Find the corresponding naturalTextDetail

      data.event = { customEvent: data.action };
      if (data.action == 'navigateTo' || data.action == 'navigatetourl' || data.action == 'navigateToUrl') {
        data.actionName = 'navigateToUrl';
        data.event = { customEvent: data.actionName };
        data.event.href = data.data[0];
        data.action = `Navigate to <span data-key=\"test-data\" data-event-key=\"href\" class=\"test_data action-flex-auto\" data-test-data-type=\"undefined\">${data.data[0]}</span>`
        data.testData = data.data[0];
      } else if (data.action === 'enter') {
        data.event.value = data.data[0];
        data.event.label = data.label;
      } else {
        data.event.label = data.label;
      }
      data.event.parsedSelector = data.locators?.parsedSelector;
      data.event.pwLocator = [data.locators?.selector || data.label];
      data.event.selector = data.locators?.selector || data.label;

      // Find natural te
      data.actionName = data.event.customEvent;
      data.type = "ACTION_TEXT";
      data.testDataType = "raw";
      data.metadata = data.description;
      data.waitTime = 30;
      data.priority = "MAJOR";
      data.isRunTime = false;
      data.ignoreStepResult = false;
      data.disabled = false;
      data.position = index;

      delete data.id;
      delete data.data;

    }));
    const Ai_task = await data.steps
      .filter(data => data.actionName !== 'navigateToUrl' && data.actionName !== 'STEP_GROUP')  // Only include objects where shouldjoin is true
      .map(data => data.name)           // Extract the name property
      .join(' ');

    const navigateStep = await data.steps
      .find(data => data.actionName == 'navigateToUrl');
    if (navigateStep) {
      testCaseSteps.push({ ...navigateStep, naturalTextActionId: 425 })
    }
    if (Ai_task) {
      let Ai_agent_template = {
        action: `AI Agent <span data-key=\"element\" data-event-key=\"ai_task\" class=\"element action-flex-auto\">${Ai_task}</span>`,
        actionName: "ai_text_actions",
        event: {
          customEvent: "ai_text_actions",
          ai_task: Ai_task,
          description: Ai_task
        },
        naturalTextActionId: 568,
        type: "ACTION_TEXT",
        disabled: false,
        ignoreStepResult: false,
        visualEnabled: false,
        position: 1
      }
      testCaseSteps.push(Ai_agent_template);
    }
    return {
      "isExtensionUsed": false,
      "name": data.name,
      "description": null,
      "status": TestCaseStatus.READY,
      "sendMailNotification": false,
      "isStepGroup": false,
      "priorityId": 1,
      "type": 1,
      "preRequisite": null,
      "isDataDriven": false,
      "workspaceVersionId": this.version.workspaceId || this.version.id,
      "deleted": false,
      "testDataStartIndex": 0,
      "tags": [],
      "steps": testCaseSteps
    };
  }

  createTestPlanData(suiteIds, name) {
    return {
      "workspaceVersionId": 1,
      "name": name,
      "description": "",
      "parallelNode": 1,
      "elementTimeOut": 30,
      "pageTimeOut": 30,
      "screenshot": "ALL_TYPES",
      "recoveryAction": "Run_Next_Testcase",
      "onAbortedAction": "Reuse_Session",
      "onSuitePreRequisiteFail": "Abort",
      "onTestCasePreRequisiteFail": "Abort",
      "onStepPreRequisiteFail": "Run_Next_Testcase",
      "reRunType": "NONE",
      "testPlanLabType": "ContextQALab",
      "testPlanType": "DISTRIBUTED",
      "hideInDashboard": false,
      "retrySessionCreation": null,
      "retrySessionCreationTimeout": null,
      "testDevices": [
        {
          "id": null,
          "browser": "chromium",
          "platform": "Browser",
          "browserVersion": null,
          "platformOsVersionId": null,
          "platformBrowserVersionId": null,
          "platformScreenResolutionId": null,
          "platformDeviceId": null,
          "capabilities": "[]",
          "deviceId": null,
          "title": name,
          "matchBrowserVersion": false,
          "createSessionAtCaseLevel": false,
          "suiteIds": suiteIds,
          "prerequisiteTestDevicesId": null,
          "prerequisiteTestDevicesIdIndex": null,
          "testPlanLabType": "ContextQALab",
          "workspaceVersionId": 1
        }
      ],
      "matchBrowserVersion": false,
      "tags": []
    }
  }
}