import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { SessionService } from "../shared/services/session.service";
import { UserPreferenceService } from "app/services/user-preference.service";
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: "root",
})
export class LoginRedirectGuard implements CanActivate {
  constructor(
    private session: SessionService,
    private router: Router,
    private userPreferenceService: UserPreferenceService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    this.session.setToken(route.params["token"], true);

    return this.userPreferenceService.show().pipe(
      switchMap((res: any) => {
        if (res.clickedSkipForNow == null) {
          res.clickedSkipForNow = 1;
          mixpanel.track('Login', {
            "email": localStorage.getItem('useremail'),
            "org_name": localStorage.getItem('useremail'),
            "is_first_time": true
          });
          return this.userPreferenceService.save(res).pipe(
            map(() => this.router.parseUrl('/landing'))
          );
        } else {
          mixpanel.track('Login', {
            "email": localStorage.getItem('useremail'),
            "org_name": localStorage.getItem('useremail'),
            "is_first_time": false
          });
          return of(this.router.parseUrl('/dashboard'));
        }
      })
    );
  }
}